<template>
  <UiPopup
    :model-value="modelValue"
    title="Connect channel"
    description="Choose channel you want to connect to your Inbox"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
  >
    <div class="flex flex-col gap-2">
      <div
        v-for="(channel, index) in channels"
        :key="index"
        class="flex flex-row items-center justify-between gap-2 rounded-xl border border-black-10 px-4 py-2"
      >
        <div class="flex flex-row items-center gap-2">
          <InboxChannelIcon :type="channel.type" />
          <p class="text-subhead-2">{{ channel.name }}</p>
        </div>
        <UiIcon v-if="channel.connected" name="check-filled" class="fill-white text-primary-100" />
        <UiButtonBase
          v-else-if="channel.can_add"
          :id="channel.type"
          size="small"
          class="!rounded-lg"
          @click="connect(channel)"
        >
          Connect
        </UiButtonBase>
        <p v-else class="text-subhead-3 flex flex-row gap-2 rounded-lg bg-primary-10 px-3 py-1.5 text-primary-100">
          <UiIcon name="clock" class="!h-5 !w-5" />
          In next version
        </p>
      </div>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import type { InboxChannel } from '@/types'
const emits = defineEmits(['update:modelValue', 'connect'])

type Props = {
  modelValue: boolean
  channels: InboxChannel[]
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const connect = (channel: InboxChannel) => {
  emits('update:modelValue', false)
  emits('connect', channel)
}
</script>

<style scoped></style>
